/*----------------------------------------------------------*\
   Spacing Sections

   Sections can be used to increase whitespace between
   content sections as needed. The recommended method is
   wrapping the content in a div and applying the class
   with the spacing you want. The -full suffix will apply
   padding to the top and bottom which is well-suited for
   content areas that span the full width of the screen
   and use a background color.

   All sections are sized in 'em' which means they will
   resize their spacing according to the font-size of the
   parent container. Smaller font-size means less spacing
   and larger font-size increases the spacing.

\*----------------------------------------------------------*/

.content-section-full {
	padding: 72px 0 48px;
	&.no-base {
		padding-bottom: 72px;
	}
	&.short-top {
		padding-top: 48px;
	}
}
.intro-section {
	padding-bottom: 24px;
}
.content-section {
	padding-top: 4em;
	padding-bottom: 3.5em;
}
.content-section-bottom {
	padding-bottom: 3.5em;
}
.content-sub-section {
	padding-bottom: 1.5em;
}
.content-sub-section-bottom {
	padding-bottom: 1.5em;
}

.intro-section {
	max-width: 900px;
	font-size: rem-calc(18);
	margin: 0 auto;
	text-align: center;
	h2 {
		font-size: rem-calc(26);
		color: $brand-accent;
		line-height: 1.2;
		margin-bottom: 12px;
	}
	.small-text {
		font-size: rem-calc(16);
	}
	&.align-left {
		p {
			text-align: left;
		}
	}
	&.no-pad {
		padding-bottom: 0;
	}
}

.section-intro {
	max-width: 770px;
	margin: 0 auto 48px;
	h2 {
		font-size: rem-calc(26);
		color: $brand-accent;
		line-height: 1.2;
		margin-bottom: 12px;
	}
}

@media screen and #{breakpoint(medium)} {
	.content-section-full {
		padding: 144px 0 120px;
		&.no-base {
			padding-bottom: 144px;
		}
		&.short-top {
			padding-top: 96px;
		}
	}
	.intro-section {
		font-size: rem-calc(24);
		padding-bottom: 72px;
		h2 {
			font-size: rem-calc(30);
			margin-bottom: 16px;
		}
		.small-text {
			font-size: rem-calc(20);
		}
	}
	.section-intro {
		font-size: rem-calc(20);
		margin-bottom: 72px;
		h2 {
			font-size: rem-calc(30);
			margin-bottom: 16px;
		}
	}
	.content-section {
		padding-top: 7em;
		padding-bottom: 6em;
	}
	.content-section-bottom {
		padding-bottom: 6em;
	}
	.content-sub-section {
		padding-top: 3em;
		padding-bottom: 1.5em;
	}
}



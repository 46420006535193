/*----------------------------------------------------------*\
    Basestyles

    Base styles that are too small for individual files. The
    animate classes listed here will be the default for
    animated elements unless overriden.

\*----------------------------------------------------------*/

body {
	background: $white;
	font-family: $main-font;
	font-size: 16px;
	color: $main-color;
	scroll-behavior: smooth;
}
p {
	font-size: 1em;
	line-height: 1.5;
	margin: 0 0 24px;
	&.intro {
		font-size: rem-calc(20);
	}
}
a {
	color: $link-base;
	transition: all .3s;
	text-decoration: none;
	&:hover,
	&:focus {
		color: $link-active;
		text-decoration: none;
	}
}
hr {
	margin: 20px 0;
}
.hide {
	display: none;
}

.extra-narrow-section {
	max-width: 860px;
	margin-left: auto;
	margin-right: auto;
}

.narrow-section {
	max-width: 900px;
	margin-left: auto;
	margin-right: auto;
}

.narrowish-section {
	max-width: 1100px;
}

.row.wide-row {
	max-width: 1330px;
}

/* @end */
.icon-grid {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-around;
	gap: 30px;
	margin: 50px auto;
}
.icon-grid-item {
	text-align: center;
	p {
		font-size: rem-calc(22);
		font-weight: $main-bold;
		line-height: 1.45;
		margin: 0;
	}
	img {
		margin-bottom: 5px;
	}
}

.icon-grid-item.alt {
	p {
		font-size: rem-calc(22);
		color: $brand-accent;
	}
	img {
		margin-bottom: 10px;
	}
}

.icon-text-grid {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr;
	gap: 50px 30px;
	@include breakpoint(400px) {
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	}
	@include breakpoint(large) {
		gap: 72px 30px;
	}
	&.col-2 {
		@include breakpoint(450px) {
			justify-content: center;
			grid-template-columns: repeat(2, minmax(200px, 370px));
		}
	}
}

.icon-text-grid-item {
	width: 100%;
	max-width: 450px;
	font-size: rem-calc(20);
	color: $brand-accent;
	text-align: center;
	margin: 0 auto;
	.heading {
		font-size: rem-calc(20);
		color: $brand-accent;
		line-height: 1.5;
		margin-bottom: 5px;
	}
	> *:last-child {
		margin-bottom: 0;
	}
}
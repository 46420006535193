/*----------------------------------------------------------*\
    Header

    All styles for the main page header are below. This
    includes the navigation elements as well.

\*----------------------------------------------------------*/

.page-header {
    padding: 15px 0;
    .inner {
    	@include grid-row;
    }
	.logo {
		@include grid-col(6);
	}
	.mobile-menu-toggle {
		@include grid-col(6);
	}
	.main-nav {
		@include grid-col;
	}
	.main-nav-small {
		@include grid-col;
	}
}

@media screen and #{breakpoint(medium)} {
	.page-header {
	    padding: 20px 0;
	    .inner {
	    	margin-bottom: 50px;
	    }
	    .logo {
	    	width: grid-col(4);
	    }
	    .main-nav {
	    	width: grid-col(8);
	    }
	}
}

@include breakpoint(768px) {
	.page-header {
	    .logo {
	    	width: grid-col(3);
	    }
	    .main-nav {
	    	width: grid-col(9);
	    	padding-left: 0;
	    }
	}
}

// Page header with background
.header-bg {
	background-color: $brand-accent;
	position: relative;
	.inner {
		margin-bottom: 0;
	}
	.mobile-contact-bar {
		background-color: darken($brand-accent, 5%);
		.button {
			background: transparent;
		}
	}
	.logo {
		img {
			margin-bottom: 0;
		}
	}
	.main-nav-small {
		top: 100%;
	}
}


.mobile-contact-bar {
	background-color: $brand-accent;
	text-align: center;
	padding: 15px;
	.button {
		background-color: $brand-accent;
		color: $white;
		font-size: rem-calc(16);
		font-family: $sans-serif-font;
		padding: 5px 10px;
		margin-bottom: 0;
		text-transform: none;
		line-height: 1.1;
		font-weight: $alt-bold;
		border: none;
		display: inline-flex;
		line-height: 1.2;
		&:first-of-type {margin-right: 10%;}
		.icon {
			height: 16px;
			margin-right: 5px;
			color: $brand;
			fill: currentColor;
			display: inline-block;
		}
		svg {
			max-height: 100%;
			max-width: 100%;
		}
		&:hover, &:focus {
			background-color: $brand;
			color: $white;
			text-decoration: none;
			.icon {
				color: $brand-accent;
			}
		}
	}
}

/*------------------------------------*\
    @group Navigation
\*------------------------------------*/

.mobile-menu-toggle {
	text-align: right;
	margin-top: 14px;
	position: relative;
	float: right!important;
	.inner {
		display: inline-block;
	}
	.nav-toggle {
		color: $white;
		cursor: pointer;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 8px;
		transition: color .3s;
		&:hover,
		&:focus,
		&.open {
			color: $brand;
			text-decoration: none;
		}
	}
	.nav-label {
		font-family: $alt-font;
		font-size: rem-calc(20);
		font-weight: 700;
		padding-top: 1px;
	}
}
.mobile-menu-anchor {
	padding: 0;
}
.main-nav {
	display: none;
}
.main-nav-small {
	background-color: $white;
	font-size: 22px;
	padding: 20px 20px 35px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
	position: absolute;
	top: 220px;
	left: 0;
	right: 0;
	z-index: 200;
	display: none;
	> ul {
		max-width: 300px;
		margin: 0 auto;
	}
	li {
		margin: 15px 0;
		&.selected > a,
		&.selected > a::after,
		&.has-dropdown.selected > a::after,
		&.has-dropdown.selected > .dropdown-parent .nav-label {
			color: $brand-accent;
		}
	}
	.has-dropdown {
		> a {
			padding-right: 19px;
			position: relative;
			display: inline;
			&::after {
				@include css-triangle(8px, currentColor, down);
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
				transition: transform .3s;
			}
		}
		.dropdown-arrow {
			width: 30px;
			height: 25px;
			background: $offwhite;
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				margin: 0;
				transition: transform .2s;
			}
			&.open img {
				transform: rotate(-180deg);
			}
		}
		.dropdown-parent {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 20px;
		}
		> a.open {
			color: $brand-accent;
			&::after {
				transform: rotate(-180deg) translateY(50%);
			}
		}
	}
	.dropdown {
		font-size: rem-calc(16);
		text-align: left;
		margin-bottom: 40px;
		display: none;
		a {
			color: $brand-accent;
			&:hover, &:focus {
				color: $brand;
			}
		}
		.selected > a {
			color: $brand;
		}
		li {
			padding: 0;
			margin-bottom: 10px;
		}
	}
}
@media only screen and (min-width:40em) and (max-width:48em) {
	.main-nav-small {
		top: 200px;
	}
}

@media only screen and (min-width:52.5em) {
	.mobile-menu-toggle,
	.mobile-menu-anchor,
	.main-nav-small,
	.mobile-contact-bar {
		display: none !important;
		transition: all .5s ease-out;
	}
	.main-nav {
		// Color is default state and alt is for alternate states (selected, hover, focus, etc.). Bg is for li and anchor hovers as needed
		$nav-color: $white;
		$nav-color-alt: $brand;
		$nav-child-color: $white;
		$nav-child-color-alt: $brand;
		font-family: $main-font;
		font-weight: 300;
		margin-top: 15px;
		display: block;
		a {
		    font-size: rem-calc(16);
		    font-weight: 700;
		    color: $nav-color;
		    line-height: 1.2;
		    padding: 5px 15px;
		    display: block;
		    text-transform: uppercase;
		}
	    a:hover,
	    a:focus,
	    li.active > a {
	        color: $brand;
	    }
		ul {
		    text-align: right;
		}
		li {
		    margin: 0 0 0 10px;
		    display: inline-block;
		    position: relative;
		    &:first-child {
		        margin-left: 0;
		    }
		    &.selected > a,
		    &.selected > a::after,
		    &.has-dropdown.selected > a::after {
		    	color: $nav-color-alt;
		    }
		}
		.has-dropdown {
			> a {
				padding-right: 24px;
				position: relative;
				display: inline-block;
				&::after {
					@include css-triangle(8px, currentColor, down);
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%);
					transition: transform .3s;
				}
			}
			&:focus, &:hover {
				.dropdown {
					opacity: 1;
					visibility: visible;
				}
			}
		}
		.dropdown {
			min-width: 180px;
			background-color: $white;
			text-align: left;
			padding: 10px 15px;
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
			border-radius: 2px;
			position: absolute;
			top: 100%;
			left: 15px;
			opacity: 0;
			z-index: 200;
			visibility: hidden;
			transition: opacity .3s ease-in, visibility .3s ease-in;
			a {
				color: $brand-accent;
				padding: 0;
				text-transform: none;
				&:hover, &:focus {
					color: $brand;
				}
			}
			li {
				padding: 0;
				margin: 0 0 5px;
				display: block;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}

.nav-slide-in {
	@include mui-slide($direction: left, $duration: .3s);
}
.nav-slide-out {@include mui-slide(out, $direction: right, $duration: .3s);
}
/* @end Navigation */
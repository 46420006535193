.product-hero {
	background-color: #232323;
	background-size: cover;
	background-position: left top;
	color: $white;
	padding: 40px 0;
	h1 {
		font-size: rem-calc(24);
		line-height: 1.19;
		color: $brand;
		&:last-child {
			margin-bottom: 0;
		}
	}
	p {
		font-size: rem-calc(16);
	}
	p:last-child {
		margin-bottom: 0;
	}
	.hero-content {
		background-color: rgba($brand-accent, .85);
		max-width: 650px;
		padding: 30px;
		margin: 0 auto;
	}
	.button {
		margin-bottom: 0;
	}
	&.bg-tile {background-image: url('/images/products/hero/bg_black_tile.jpg');}
	&.bg-hardwood {background-image: url('/images/products/hero/bg_hard_wood_floors.jpg');}
	&.bg-carpet {background-image: url('/images/products/hero/bg_bedroom_carpet.jpg');}
	&.bg-laminate-floors {background-image: url('/images/products/hero/bg_laminate_entry.jpg');}
	&.bg-area-rugs {background-image: url('/images/products/hero/bg_area_rug_dining_room.jpg');}
	&.bg-area-rugs {background-image: url('/images/products/hero/bg_area_rug_dining_room.jpg');}
	&.bg-vinyl-floors {background-image: url('/images/products/hero/bg_luxury_vinyl.jpg');}
	&.bg-window-treatments {background-image: url('https://eagle-floors.imgix.net/hero/bg_window_treatments.jpg?auto=format&fit=crop&w=640&h=560'); background-color: #d5d4d1;}
	&.bg-light-kitchen-cabinets {background-image: url('https://eagle-floors.imgix.net/hero/bg_light_kitchen_cabinets.jpg?auto=format&fit=crop&w=640&h=560'); background-color: #806639}
	&.bg-white-quartz-countertop {background-image: url('https://eagle-floors.imgix.net/hero/bg_white_quartz_countertop.jpg?auto=format&fit=crop&w=640&h=560'); background-color: #a5a9af;}
	&.bg-window-coverings {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_window_coverings.jpg?auto=format&fit=crop&w=640&h=560'); background-color: #B48857;}
	&.bg-hardwood-floors {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_hardwood_floors.jpg?auto=format&fit=crop&w=640&h=560'); background-color: #d0c1a4;}
	&.bg-luxury-vinyl-plank {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_luxury_vinyl_plank.jpg?auto=format&fit=crop&w=640&h=560'); background-color: #d0c1a4;}
	&.bg-carpet-dog {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_carpet.jpg?auto=format&fit=crop&w=640&h=560'); background-color: #d2d0d5;}
	&.bg-tile-bathroom {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_tile.jpg?auto=format&fit=crop&w=640&h=560'); background-color: #d2d0d5;}
	&.bg-laminate {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_laminate.jpg?auto=format&fit=crop&w=640&h=560'); background-color: #d2d0d5;}
	&.bg-area-rugs-fireplace {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_area_rugs.jpg?auto=format&fit=crop&w=640&h=560'); background-color: #d2d0d5;}
	&.bg-cabinets {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_cabinets.jpg?auto=format&fit=crop&w=640&h=560'); background-color: #d2d0d5;}
	&.bg-countertops {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_countertops.jpg?auto=format&fit=crop&w=640&h=560'); background-color: #d2d0d5;}
}

@include breakpoint(medium) {
	.product-hero {
		padding: 52px 0;
		h1 {
			font-size: rem-calc(32);
		}
		p {
			font-size: rem-calc(20);
		}
		.hero-content {
			padding: 40px;
		}
		.column {
			min-height: 456px;
			display: flex;
			align-items: center;
		}
		&.bg-tile {background-image: url('/images/products/hero/bg_black_tile_med.jpg');}
		&.bg-hardwood {background-image: url('/images/products/hero/bg_hard_wood_floors_med.jpg');}
		&.bg-carpet {background-image: url('/images/products/hero/bg_bedroom_carpet_med.jpg');}
		&.bg-laminate-floors {background-image: url('/images/products/hero/bg_laminate_entry_med.jpg');}
		&.bg-area-rugs {background-image: url('/images/products/hero/bg_area_rug_dining_room_med.jpg');}
		&.bg-vinyl-floors {background-image: url('/images/products/hero/bg_luxury_vinyl_med.jpg');}
		&.bg-window-treatments {background-image: url('https://eagle-floors.imgix.net/hero/bg_window_treatments.jpg?auto=format&fit=crop&w=1024&h=560&crop=right');}
		&.bg-light-kitchen-cabinets {background-image: url('https://eagle-floors.imgix.net/hero/bg_light_kitchen_cabinets.jpg?auto=format&fit=crop&w=1024&h=560&crop=right');;}
		&.bg-white-quartz-countertop {background-image: url('https://eagle-floors.imgix.net/hero/bg_white_quartz_countertop.jpg?auto=format&fit=crop&w=1024&h=560&crop=right');}
		&.bg-window-coverings {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_window_coverings.jpg?auto=format&fit=crop&w=1024&h=560&crop=right');}
		&.bg-hardwood-floors {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_hardwood_floors.jpg?auto=format&fit=crop&w=1024&h=560&crop=right');}
		&.bg-luxury-vinyl-plank {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_luxury_vinyl_plank.jpg?auto=format&fit=crop&w=1024&h=560&crop=right');}
		&.bg-carpet-dog {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_carpet.jpg?auto=format&fit=crop&w=1024&h=560&crop=right');}
		&.bg-tile-bathroom {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_tile.jpg?auto=format&fit=crop&w=1024&h=560&crop=right');}
		&.bg-laminate {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_laminate.jpg?auto=format&fit=crop&w=1024&h=560&crop=right');}
		&.bg-area-rugs-fireplace {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_area_rugs.jpg?auto=format&fit=crop&w=1024&h=560&crop=right');}
		&.bg-cabinets {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_cabinets.jpg?auto=format&fit=crop&w=1024&h=560&crop=right');}
		&.bg-countertops {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_countertops.jpg?auto=format&fit=crop&w=1024&h=560&crop=right');}
	}
}

@include breakpoint(large) {
	.product-hero {
		.hero-content {
			margin: 0;
		}
		&.tall {
			height: 650px;
			display: flex;
			align-items: center;
			.inner {
				width: 100%;
			}
		}
		&.bg-tile {background-image: url('/images/products/hero/bg_black_tile_lrg.jpg');}
		&.bg-hardwood {background-image: url('/images/products/hero/bg_hard_wood_floors_lrg.jpg');}
		&.bg-carpet {background-image: url('/images/products/hero/bg_bedroom_carpet_lrg.jpg');}
		&.bg-laminate-floors {background-image: url('/images/products/hero/bg_laminate_entry_lrg.jpg');}
		&.bg-area-rugs {background-image: url('/images/products/hero/bg_area_rug_dining_room_lrg.jpg');}
		&.bg-vinyl-floors {background-image: url('/images/products/hero/bg_luxury_vinyl_lrg.jpg');}
		&.bg-window-treatments {background-image: url('https://eagle-floors.imgix.net/hero/bg_window_treatments.jpg?auto=format&w=1680');}
		&.bg-light-kitchen-cabinets {background-image: url('https://eagle-floors.imgix.net/hero/bg_light_kitchen_cabinets.jpg?auto=format&w=1680');}
		&.bg-white-quartz-countertop {background-image: url('https://eagle-floors.imgix.net/hero/bg_white_quartz_countertop.jpg?auto=format&w=1680');}
		&.bg-window-coverings {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_window_coverings.jpg?auto=format&w=1680');}
		&.bg-hardwood-floors {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_hardwood_floors.jpg?auto=format&w=1680');}
		&.bg-luxury-vinyl-plank {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_luxury_vinyl_plank.jpg?auto=format&w=1680');}
		&.bg-carpet-dog {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_carpet.jpg?auto=format&w=1680');}
		&.bg-tile-bathroom {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_tile.jpg?auto=format&w=1680');}
		&.bg-laminate {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_laminate.jpg?auto=format&w=1680');}
		&.bg-area-rugs-fireplace {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_area_rugs.jpg?auto=format&w=1680');}
		&.bg-cabinets {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_cabinets.jpg?auto=format&w=1680');}
		&.bg-countertops {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_countertops.jpg?auto=format&w=1680');}
	}
}


@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
only screen and (-o-min-device-pixel-ratio: 13/10),
only screen and (min-resolution: 120dpi) {
	.product-hero {
		&.bg-tile {background-image: url('/images/products/hero/bg_black_tile@2x.jpg');}
		&.bg-hardwood {background-image: url('/images/products/hero/bg_hard_wood_floors@2x.jpg');}
		&.bg-carpet {background-image: url('/images/products/hero/bg_bedroom_carpet@2x.jpg');}
		&.bg-laminate-floors {background-image: url('/images/products/hero/bg_laminate_entry@2x.jpg');}
		&.bg-area-rugs {background-image: url('/images/products/hero/bg_area_rug_dining_room@2x.jpg');}
		&.bg-vinyl-floors {background-image: url('/images/products/hero/bg_luxury_vinyl@2x.jpg');}
		&.bg-window-treatments {background-image: url('https://eagle-floors.imgix.net/hero/bg_window_treatments.jpg?auto=format&fit=crop&w=640&h=560&dpr=2&q=20');}
		&.bg-light-kitchen-cabinets {background-image: url('https://eagle-floors.imgix.net/hero/bg_light_kitchen_cabinets.jpg?auto=format&fit=crop&w=640&h=560&dpr=2&q=20');}
		&.bg-white-quartz-countertop {background-image: url('https://eagle-floors.imgix.net/hero/bg_white_quartz_countertop.jpg?auto=format&fit=crop&w=640&h=560&dpr=2&q=20');}
		&.bg-window-coverings {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_window_coverings.jpg?auto=format&fit=crop&w=640&h=560&dpr=2&q=20');}
		&.bg-hardwood-floors {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_hardwood_floors.jpg?auto=format&fit=crop&w=640&h=560&dpr=2&q=20');}
		&.bg-luxury-vinyl-plank {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_luxury_vinyl_plank.jpg?auto=format&fit=crop&w=640&h=560&dpr=2&q=20');}
		&.bg-carpet-dog {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_carpet.jpg?auto=format&fit=crop&w=640&h=560&dpr=2&q=20');}
		&.bg-tile-bathroom {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_tile.jpg?auto=format&fit=crop&w=640&h=560&dpr=2&q=20');}
		&.bg-laminate {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_laminate.jpg?auto=format&fit=crop&w=640&h=560&dpr=2&q=20');}
		&.bg-area-rugs-fireplace {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_area_rugs.jpg?auto=format&fit=crop&w=640&h=560&dpr=2&q=20');}
		&.bg-cabinets {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_cabinets.jpg?auto=format&fit=crop&w=640&h=560&dpr=2&q=20');}
		&.bg-countertops {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_countertops.jpg?auto=format&fit=crop&w=640&h=560&dpr=2&q=20');}
	}
	@include breakpoint(medium) {
		.product-hero {
			&.bg-tile {background-image: url('/images/products/hero/bg_black_tile_med@2x.jpg');}
			&.bg-hardwood {background-image: url('/images/products/hero/bg_hard_wood_floors_med@2x.jpg');}
			&.bg-carpet {background-image: url('/images/products/hero/bg_bedroom_carpet_med@2x.jpg');}
			&.bg-laminate-floors {background-image: url('/images/products/hero/bg_laminate_entry_med@2x.jpg');}
			&.bg-area-rugs {background-image: url('/images/products/hero/bg_area_rug_dining_room_med@2x.jpg');}
			&.bg-vinyl-floors {background-image: url('/images/products/hero/bg_luxury_vinyl_med@2x.jpg');}
			&.bg-window-treatments {background-image: url('https://eagle-floors.imgix.net/hero/bg_window_treatments.jpg?auto=format&fit=crop&w=1024&h=560&crop=right&dpr=2&q=20');}
			&.bg-light-kitchen-cabinets {background-image: url('https://eagle-floors.imgix.net/hero/bg_light_kitchen_cabinets.jpg?auto=format&fit=crop&w=1024&h=560&crop=right&dpr=2&q=20');}
			&.bg-white-quartz-countertop {background-image: url('https://eagle-floors.imgix.net/hero/bg_white_quartz_countertop.jpg?auto=format&fit=crop&w=1024&h=560&crop=right&dpr=2&q=20');}
			&.bg-window-coverings {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_window_coverings.jpg?auto=format&fit=crop&w=1024&h=560&crop=right&dpr=2&q=20');}
			&.bg-hardwood-floors {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_hardwood_floors.jpg?auto=format&fit=crop&w=1024&h=560&crop=right&dpr=2&q=20');}
			&.bg-luxury-vinyl-plank {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_luxury_vinyl_plank.jpg?auto=format&fit=crop&w=1024&h=560&crop=right&dpr=2&q=20');}
			&.bg-carpet-dog {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_carpet.jpg?auto=format&fit=crop&w=1024&h=560&crop=right&dpr=2&q=20');}
			&.bg-tile-bathroom {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_tile.jpg?auto=format&fit=crop&w=1024&h=560&crop=right&dpr=2&q=20');}
			&.bg-laminate {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_laminate.jpg?auto=format&fit=crop&w=1024&h=560&crop=right&dpr=2&q=20');}
			&.bg-area-rugs-fireplace {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_area_rugs.jpg?auto=format&fit=crop&w=1024&h=560&crop=right&dpr=2&q=20');}
			&.bg-cabinets {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_cabinets.jpg?auto=format&fit=crop&w=1024&h=560&crop=right&dpr=2&q=20');}
			&.bg-countertops {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_countertops.jpg?auto=format&fit=crop&w=1024&h=560&crop=right&dpr=2&q=20');}
		}
	}
	@include breakpoint(large) {
		.product-hero {
			&.bg-tile {background-image: url('/images/products/hero/bg_black_tile_lrg@2x.jpg');}
			&.bg-hardwood {background-image: url('/images/products/hero/bg_hard_wood_floors_lrg@2x.jpg');}
			&.bg-carpet {background-image: url('/images/products/hero/bg_bedroom_carpet_lrg@2x.jpg');}
			&.bg-laminate-floors {background-image: url('/images/products/hero/bg_laminate_entry_lrg@2x.jpg');}
			&.bg-area-rugs {background-image: url('/images/products/hero/bg_area_rug_dining_room_lrg@2x.jpg');}
			&.bg-vinyl-floors {background-image: url('/images/products/hero/bg_luxury_vinyl_lrg@2x.jpg');}
			&.bg-window-treatments {background-image: url('https://eagle-floors.imgix.net/hero/bg_window_treatments.jpg?auto=format&w=1680&dpr=2&q=20');}
			&.bg-light-kitchen-cabinets {background-image: url('https://eagle-floors.imgix.net/hero/bg_light_kitchen_cabinets.jpg?auto=format&w=1680&dpr=2&q=20');}
			&.bg-white-quartz-countertop {background-image: url('https://eagle-floors.imgix.net/hero/bg_white_quartz_countertop.jpg?auto=format&w=1680&dpr=2&q=20');}
			&.bg-window-coverings {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_window_coverings.jpg?auto=format&w=1680&dpr=2&q=20');}
			&.bg-hardwood-floors {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_hardwood_floors.jpg?auto=format&w=1680&dpr=2&q=20');}
			&.bg-luxury-vinyl-plank {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_luxury_vinyl_plank.jpg?auto=format&w=1680&dpr=2&q=20');}
			&.bg-carpet-dog {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_carpet.jpg?auto=format&w=1680&dpr=2&q=20');}
			&.bg-tile-bathroom {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_tile.jpg?auto=format&w=1680&dpr=2&q=20');}
			&.bg-laminate {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_laminate.jpg?auto=format&w=1680&dpr=2&q=20');}
			&.bg-area-rugs-fireplace {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_area_rugs.jpg?auto=format&w=1680&dpr=2&q=20');}
			&.bg-cabinets {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_cabinets.jpg?auto=format&w=1680&dpr=2&q=20');}
			&.bg-countertops {background-image: url('https://eagle-floors.imgix.net/care/hero/bg_countertops.jpg?auto=format&w=1680&dpr=2&q=20');}
		}
	}
}

.product-intro-section {
	max-width: 640px;
	margin: 0 auto;
	h2 {
		font-size: rem-calc(30);
		color: $brand-accent;
		text-align: center;
		line-height: 1.2;
		margin-bottom: 16px;
	}
	p {
		font-size: rem-calc(22);
		line-height: 1.45;
	}
	img {
		display: block;
		margin: 0 auto 20px;
	}
}

.product-section-heading {
	font-size: rem-calc(24);
	color: $brand;
	line-height: 1.25;
	text-align: center;
	margin-bottom: 42px;
}

.product-form-section {
	background-size: cover;
	background-color: #1e5c7c;
	background-position: left top;
	color: $white;
	padding: 40px 0 24px;
	h2 {
		font-size: rem-calc(24);
		margin-bottom: 12px;
		.highlight {
			color: $white;
		}
	}
	.text {
		max-width: 860px;
	}
}

.product-form {
	input[type="text"],
	input[type="email"],
	textarea {
		width: 100%;
		border: 2px solid $brand;
		margin-bottom: 20px;
	}
	input[type="text"],
	input[type="email"] {
		height: 50px;
		padding: 10px;
	}
	textarea {
		min-height: 144px;
		padding: 10px;
	}
	.field-name {
		font-weight: $main-bold;
		display: block;
	}
}

@include breakpoint(medium) {
	.product-form {
		display: grid;
		grid-column-gap: 30px;
		grid-template-columns: 1fr 1fr;
		.submit {
			grid-column: span 2;
		}
	}
}

@include breakpoint(large) {
	.product-form {
		display: block;
		.field-group {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 30px;
		}
	}
	.product-form-section {
		padding: 110px 0 90px;
		h2 {
			font-size: rem-calc(30);
		}
		.column {
			display: flex;
			justify-content: space-between;
		}
		.text {
			font-size: rem-calc(18);
			flex-basis: 31%;
		}
		.form {
			flex-basis: 65%;
		}
	}
}

.product-form-section {
	&.bg-tile {background-image: url('/images/products/footer/bg_tile_backsplash.jpg');}
	&.bg-hardwood {background-image: url('/images/products/footer/bg_hardwood_living_room.jpg');}
	&.bg-laminate-floors {background-image: url('/images/products/footer/bg_laminate_flooring.jpg');}
	&.bg-carpet, &.bg-area-rugs {background-image: url('/images/products/footer/bg_area_rug.jpg');}
	&.bg-vinyl-floors {background-image: url('/images/products/footer/bg_vinyl_flooring.jpg');}
}

@include breakpoint(medium) {
	.product-form-section {
		&.bg-tile {background-image: url('/images/products/footer/bg_tile_backsplash_med.jpg');}
		&.bg-hardwood {background-image: url('/images/products/footer/bg_hardwood_living_room_med.jpg');}
		&.bg-laminate-floors {background-image: url('/images/products/footer/bg_laminate_flooring_med.jpg');}
		&.bg-carpet, &.bg-area-rugs {background-image: url('/images/products/footer/bg_area_rug_med.jpg');}
		&.bg-vinyl-floors {background-image: url('/images/products/footer/bg_vinyl_flooring_med.jpg');}
	}
}

@include breakpoint(large) {
	.product-form-section {
		&.bg-tile {background-image: url('/images/products/footer/bg_tile_backsplash_lrg.jpg');}
		&.bg-hardwood {background-image: url('/images/products/footer/bg_hardwood_living_room_lrg.jpg');}
		&.bg-laminate-floors {background-image: url('/images/products/footer/bg_laminate_flooring_lrg.jpg');}
		&.bg-carpet, &.bg-area-rugs {background-image: url('/images/products/footer/bg_area_rug_lrg.jpg');}
		&.bg-vinyl-floors {background-image: url('/images/products/footer/bg_vinyl_flooring_lrg.jpg');}
	}
}



// Product hero with box bottom aligned
.product-hero-bottom {
	background-size: cover;
	background-position: left top;
	color: $white;
	overflow: hidden;
	h1 {
		font-size: rem-calc(24);
		line-height: 1.25;
		color: $white;
	}
	p {
		font-size: rem-calc(16);
	}
	p:last-child {
		margin-bottom: 0;
	}
	.hero-content {
		background-color: rgba($brand-accent, .85);
		padding: 30px;
		margin: 0 auto;
	}
	.button {
		margin-bottom: 0;
	}
	.hero-button-group {
		display: flex;
		flex-wrap: wrap;
		gap: 15px 30px;
	}
	.product-hero-image-small {
		min-width: 100%;
		height: 300px;
		display: flex;
		align-items: stretch;
		justify-content: center;
		img {
			width: auto;
			min-width: 100%;
			max-width: min-content;
			margin: 0;
		}
	}
}

@include breakpoint(medium) {
	.product-hero-bottom {
		min-height: 800px;
		h1 {
			font-size: rem-calc(32);
			line-height: 1.18;
		}
		p {
			font-size: rem-calc(20);
		}
		.hero-content {
			padding: 40px;
		}
		.product-hero-image-small {
			height: 466px;
		}
	}
}

@include breakpoint(large) {
	.product-hero-bottom {
		width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: flex-start;
		.inner {
			width: 100%;
			max-width: 75em;
			padding-right: .9375rem;
		    padding-left: .9375rem;
		    margin: 0 auto;
		}
		.hero-content {
			max-width: 900px;
			margin: 0;
		}
		.product-hero-image-small {
			display: none;
		}
	}
}
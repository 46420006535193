/*----------------------------------------------------------*\
   Headings

   The headings follow a modular scale and vertical rhythm
   that changes between small and larger views. To keep
   your headings semantic, you can apply the heading classes
   to different headings for stylistic purposes while
   still using the appropriate heading hierarchy in your
   HTML.

\*----------------------------------------------------------*/

h1, h2, h3, h4 {
   font-family: $heading-font;
}

h1, .h1 {
   font-size: rem-calc(28);
   font-weight: $alt-bold;
   color: $brand-accent;
   line-height: 1;
   margin: 0 0 .5em;
   letter-spacing: -0.04em;
}

h2, .h2 {
   font-size: rem-calc(28);
   font-weight: $alt-bold;
   color: $brand;
   line-height: 1.059;
   margin: 0 0 .5em;
   letter-spacing: -0.03em;
}
h3, .h3 {
   font-size: rem-calc(22);
   font-weight: $alt-bold;
   color: $brand-accent;
   line-height: 1.11;
   margin: 0 0 .5em;
   letter-spacing: -0.02em;
}
h4, .h4 {
   font-size: rem-calc(20);
   font-weight: $alt-normal;
   color: $gray;
   line-height: 1.3636;
   margin: 0 0 .5em;
}


.underline-center {
    text-align: center;
    margin-bottom: 2rem;
    &:after {
         margin: 20px auto;
         background: none repeat scroll 0 0 rgba($brand-accent, 0.3);
         bottom: 0px;
         content: "";
         display: block;
         height: 2px;
         position: relative;
         width: 30px;
     }
}

.underline {
    position: relative;
    margin-bottom: 3rem;
    &:after {
         margin: 10px 0 0;
         background: none repeat scroll 0 0 rgba($brand-accent, 0.3);
         bottom: -10px;
         content: "";
         display: block;
         height: 2px;
         position: relative;
         width: 30px;
     }
     &.white:after {
      background: none repeat scroll 0 0 rgba($white, 0.3);
     }
}

@media screen and #{breakpoint(medium)} {
	h1, .h1 {
	   font-size: rem-calc(48);
	   line-height: 1;
	}

	h2, .h2 {
	   font-size: rem-calc(42);
	   line-height: 1.077;
	}

	h3, .h3 {
	   font-size: rem-calc(32);
	   line-height: 1.125;
	}

	h4, .h4 {
	   font-size: rem-calc(26);
	   line-height: 1.2;
	}

   .underline-center {
       margin-bottom: 3rem;
       &:after {
            margin: 30px auto 20px;
            background: none repeat scroll 0 0 rgba($brand-accent, 0.3);
            bottom: 0px;
            content: "";
            display: block;
            height: 2px;
            position: relative;
            width: 60px;
        }
   }

   .underline {
       position: relative;
       margin-bottom: 3rem;
       &:after {
            margin: 20px 0 0;
            background: none repeat scroll 0 0 rgba($brand-accent, 0.3);
            bottom: -10px;
            content: "";
            display: block;
            height: 2px;
            position: relative;
            width: 60px;
        }
        &.white:after {
         background: none repeat scroll 0 0 rgba($white, 0.3);
        }
   }
}


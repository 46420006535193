/*------------------------------------*\
    @group Hero
\*------------------------------------*/
	//Add styles here for hero
/* @end Hero */

.hero {
	background-color: $brand-accent;
	background-image: url('/images/cssimg/bg_hero_placeholder.jpg');
	background-position: bottom center;
	background-size: cover;
	background-repeat: no-repeat;
	padding: 10px 0 80px;
	color: $white;
	height: auto;
	.hero-inner {
		max-width: 700px;
	}
	h1 {
		color: $white;
		margin-bottom: 20px;
	}
	.button {
		margin-bottom: 0;
	}
	&.bg-showroom-exterior {
		background-image: url('https://eagle-floors.imgix.net/hero/bg_showroom_exterior.jpg?auto=format&w=640');
	}
	&.bg-kitchen-wood-floors {
		background-image: url('https://eagle-floors.imgix.net/hero/bg_kitchen_wood_floors.jpg?auto=format&w=640');
	}
}


@media screen and #{breakpoint(medium)} {
	.hero {
		background-image: url('/images/cssimg/bg_hero_placeholder_med.jpg');
		padding: 28px 0 120px;
		display: flex;
		flex-direction: column;
		> .row {
			width: 100%;
			margin: auto;
		}
		h1 {
			margin-bottom: 30px;
		}
		&.bg-showroom-exterior {
			background-image: url('https://eagle-floors.imgix.net/hero/bg_showroom_exterior.jpg?auto=format&w=1000');
		}
		&.bg-kitchen-wood-floors {
			background-image: url('https://eagle-floors.imgix.net/hero/bg_kitchen_wood_floors.jpg?auto=format&w=1000');
		}
	}
}

@media screen and #{breakpoint(large)} {
	.hero {
		background-image: url('/images/cssimg/bg_hero_placeholder_lrg.jpg');
		background-size: cover;
		height: 800px;
		.hero-inner {
			max-width: 8 0px;
		}
		h1 {
			font-size: rem-calc(50);
		}
		.button {
			padding: 20px 30px;
		}
		&.bg-showroom-exterior {
			background-image: url('https://eagle-floors.imgix.net/hero/bg_showroom_exterior.jpg?auto=format&w=1440');
		}
		&.bg-kitchen-wood-floors {
			background-image: url('https://eagle-floors.imgix.net/hero/bg_kitchen_wood_floors.jpg?auto=format&w=1440');
		}
	}
}


@media screen and (min-width: 1440px) {
	.hero {
		background-image: url('/images/cssimg/bg_hero_placeholder_xl.jpg');
		&.bg-showroom-exterior {
			background-image: url('https://eagle-floors.imgix.net/hero/bg_showroom_exterior.jpg?auto=format&w=1920');
		}
		&.bg-kitchen-wood-floors {
			background-image: url('https://eagle-floors.imgix.net/hero/bg_kitchen_wood_floors.jpg?auto=format&w=1920');
		}
	}
}


.hero-inner.wide {
	max-width: 100%;
}

.hero-video-embed {
	.video {
		margin-bottom: 50px;
		> * {
			max-width: 600px;
			margin: 0 auto;
		}
	}
}

@include breakpoint(850px) {
	.hero-video-embed {
		display: flex;
		gap: 45px;
		align-items: center;
		.hero-inner {
			max-width: 100%;
		}
		.text {
			flex-basis: 630px;
			order: 0;
		}
		.video {
			margin-bottom: 0;
			flex-basis: 600px;
			order: 1;
		}
	}
}

// SHort hero
.short-hero {
	background-color: #232323;
	background-size: cover;
	background-position: left top;
	color: $white;
	padding: 40px 0;
	h1 {
		font-size: rem-calc(24);
		line-height: 1.19;
		color: $white;
		&:last-child {
			margin-bottom: 0;
		}
	}
	p {
		font-size: rem-calc(16);
	}
	p:last-child {
		margin-bottom: 0;
	}
	.hero-content {
		background-color: rgba($brand-accent, .85);
		max-width: 450px;
		padding: 30px;
		margin: 0 auto;
	}
	&.bg-flooring-racks-table {background-image: url('https://eagle-floors.imgix.net/hero/bg_flooring_racks_table.jpg?auto=format&fit=crop&w=640&h=560');}
}

@include breakpoint(medium) {
	.short-hero {
		padding: 52px 0;
		h1 {
			font-size: rem-calc(32);
		}
		p {
			font-size: rem-calc(20);
		}
		.hero-content {
			padding: 40px;
		}
		.column {
			min-height: 456px;
			display: flex;
			align-items: center;
		}
		&.bg-flooring-racks-table {background-image: url('https://eagle-floors.imgix.net/hero/bg_flooring_racks_table.jpg?auto=format&fit=crop&w=1024&h=560&crop=right');}
	}
}

@include breakpoint(large) {
	.short-hero {
		.hero-content {
			margin: 0;
		}
		&.bg-flooring-racks-table {background-image: url('https://eagle-floors.imgix.net/hero/bg_flooring_racks_table.jpg?auto=format&w=1680');}
	}
}


@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
only screen and (-o-min-device-pixel-ratio: 13/10),
only screen and (min-resolution: 120dpi) {
	.short-hero {
		&.bg-flooring-racks-table {background-image: url('https://eagle-floors.imgix.net/hero/bg_flooring_racks_table.jpg?auto=format&fit=crop&w=640&h=560&dpr=2&q=20');}
	}
	@include breakpoint(medium) {
		.short-hero {
			&.bg-flooring-racks-table {background-image: url('https://eagle-floors.imgix.net/hero/bg_flooring_racks_table.jpg?auto=format&fit=crop&w=1024&h=560&crop=right&dpr=2&q=20');}
		}
	}
	@include breakpoint(large) {
		.short-hero {
			&.bg-flooring-racks-table {background-image: url('https://eagle-floors.imgix.net/hero/bg_flooring_racks_table.jpg?auto=format&w=1680&dpr=2&q=20g');}
		}
	}
}

// Hero with content box
.hero-content-box{
	background-color: #232323;
	background-size: cover;
	background-position: left top;
	color: $white;
	padding: 40px 0;
	h1 {
		font-size: rem-calc(24);
		line-height: 1.19;
		color: $white;
		&:last-child {
			margin-bottom: 0;
		}
	}
	p {
		font-size: rem-calc(16);
	}
	p:last-child {
		margin-bottom: 0;
	}
	.hero-content {
		background-color: rgba($brand-accent, .85);
		max-width: 650px;
		padding: 30px;
		margin: 0 auto;
	}
	.button {
		margin-bottom: 0;
	}
	&.bg-commercial-building {background-image: url('https://eagle-floors.imgix.net/hero/bg_commercial_building.jpg?auto=format&fit=crop&w=640&h=560'); background-color: #cdc0b5;}
}

@include breakpoint(medium) {
	.hero-content-box{
		padding: 52px 0;
		h1 {
			font-size: rem-calc(32);
		}
		p {
			font-size: rem-calc(20);
		}
		.hero-content {
			padding: 40px;
		}
		.column {
			min-height: 456px;
			display: flex;
			align-items: center;
		}
		&.bg-commercial-building {background-image: url('https://eagle-floors.imgix.net/hero/bg_commercial_building.jpg?auto=format&fit=crop&w=1024&h=560&crop=right');}
	}
}

@include breakpoint(large) {
	.hero-content-box{
		.hero-content {
			margin: 0;
		}
		&.tall {
			height: 650px;
			display: flex;
			align-items: center;
			.inner {
				width: 100%;
			}
		}
		&.bg-commercial-building {background-image: url('https://eagle-floors.imgix.net/hero/bg_commercial_building.jpg?auto=format&w=1680');}
	}
}


@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
only screen and (-o-min-device-pixel-ratio: 13/10),
only screen and (min-resolution: 120dpi) {
	.hero-content-box{
		&.bg-commercial-building {background-image: url('https://eagle-floors.imgix.net/hero/bg_commercial_building.jpg?auto=format&fit=crop&w=640&h=560&dpr=2&q=20');}
	}
	@include breakpoint(medium) {
		.hero-content-box{
			&.bg-commercial-building {background-image: url('https://eagle-floors.imgix.net/hero/bg_commercial_building.jpg?auto=format&fit=crop&w=1024&h=560&crop=right&dpr=2&q=20');}
		}
	}
	@include breakpoint(large) {
		.hero-content-box{
			&.bg-commercial-building {background-image: url('https://eagle-floors.imgix.net/hero/bg_commercial_building.jpg?auto=format&w=1680&dpr=2&q=20');}
		}
	}
}
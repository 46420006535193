/*----------------------------------------------------------*\
    Patterns

    All base site patterns are located in this file.

\*----------------------------------------------------------*/


/*------------------------------------*\
    @group Animated Hamburger icon
\*------------------------------------*/

.hamburger {
	width: 24px;
	height: 20px;
	font-size: 0;
	margin: 0;
	padding: 0;
	transition: background 0.3s;
	cursor: pointer;
	display: block;
	position: relative;
	overflow: hidden;
	&:focus {
		outline: none;
	}
	span {
		height: 2px;
		background: $white;
		display: block;
		position: absolute;
		top: 10px;
		left: 0;
		right: 0;
		&::before,
		&::after {
			content: '';
			background-color: $white;
			width: 100%;
			height: 2px;
			display: block;
			position: absolute;
			left: 0;
		}
		&::before {
			top: -6px;
		}
		&::after {
			bottom: -6px;
		}
	}
}

.hamburger.dark {
	span {
		background: $brand-accent;
		&::before,
		&::after {
			background-color: $brand-accent;
		}
	}
}

.open .hamburger span {
	background-color: $brand;
	&::before,
	&::after {
		background-color: $brand;
	}
}

.hamburger-x {
	span {
		transition: background 0s 0.3s;
		&::before,
		&::after {
			transition-duration: 0.3s, 0.3s;
			transition-delay: 0.3s, 0s;
		}
		&::before {
			transition-property: top, transform;
		}
		&::after {
			transition-property: bottom, transform;
		}
	}
}

//Active state, add .active with JS
.hamburger-x.active {
	span {
		background: none;
		&::before {
			top: 0;
			transform: rotate(45deg);
		}
		&::after {
			bottom: 0;
			transform: rotate(-45deg);
		}
		&::before,
		&::after {
			transition-delay: 0s, 0.3s;
		}
	}

}

/* @end Animated Hamburger icon */

/*------------------------------------*\
    @group Media Objects
\*------------------------------------*/

.media-object {
	max-width: 500px;
	margin: 0 auto 48px;
	.media {
		text-align: center;
		margin-bottom: 20px;
	}
	h2, h3 {
		font-size: rem-calc(24);
		color: $brand-accent;
		line-height: 1.25;
		letter-spacing: 0;
	}
	h4 {
		font-size: rem-calc(20);
		font-weight: $alt-bold;
		color: $brand-accent;
	}
}


@include breakpoint(860px) {
	.media-object {
		max-width: 100%;
		display: flex;
		margin-bottom: 80px;
		.media {
			padding-right: 40px;
			margin-bottom: 0;
		}
		.text {
			flex-basis: 70%;
		}
	}
}

@include breakpoint(large) {
	.media-object {
		.media {
			flex-shrink: 0;
			padding-right: 60px;
		}
		.text {
			font-size: rem-calc(20);
		}
	}
}

/* @end Media Objects */


/*------------------------------------*\
    @group Highlight Grid
\*------------------------------------*/

.highlight-grid {
	display: grid;
	grid-gap: 30px;
	.grid-item {
		max-width: 570px;
		margin: 0 auto;
	}
	img {
		margin-bottom: 30px;
	}
}

@include breakpoint(420px) {
	.highlight-grid {
		grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
	}
}

/* @end Highlight Grid */


/*------------------------------------*\
    @group Tile Links
\*------------------------------------*/

.tile-links-section {
	h3 {
		color: $gray;
		margin-bottom: 30px;
	}
}

.tile-links {
	margin-bottom: 50px;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
	grid-gap: 35px;
	justify-content: center;
	a {
		max-width: 200px;
		margin: 0 auto;
		display: block;
	}
	img {
		border-radius: 4px;
		margin-bottom: 10px;
	}
	p {
		font-size: rem-calc(20);
		font-weight: $main-bold;
		line-height: 1.25;
		margin: 0;
	}
	.tile-link-gallery {
		font-size: rem-calc(14);
		display: flex;
		align-items: center;
		margin-top: 3px;
		img {
			margin: 0 5px 0 0;
		}
	}
}

@media screen and #{breakpoint(large)} {
	.tile-links {
		grid-gap: 70px;
	}
}

/* @end Tile Links */


/*------------------------------------*\
    @group Text overlap cards
\*------------------------------------*/

.text-overlap-cards {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px;
}

.text-overlap-card {
	max-width: 636px;
	border-radius: 4px;
	justify-self: center;
	position: relative;
	overflow: hidden;
	img {
		position: relative;
		z-index: 5;
	}
	&::after {
		content: '';
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
		width: 100%;
		height: 100%;
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 10;
	}
}

.text-overlap-card-content {
	width: 100%;
	color: $white;
	padding: 30px;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 20;
	h3 {
		color: $white;
		margin-bottom: 5px;
	}
	p {
		font-size: rem-calc(15);
		line-height: 1.5;
		margin: 0;
	}
}

.text-overlap-cards.action-footer {
	.text-overlap-card {
		p {
			margin-bottom: 16px;
		}
		.button {
			margin-bottom: 0;
		}
	}
}

@include breakpoint(medium) {
	.text-overlap-cards {
		grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
	}
	.text-overlap-card-content {
		p {
			font-size: rem-calc(18);
		}
	}
}

@include breakpoint(large) {
	.text-overlap-cards {
		grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
	}
	.text-overlap-card {
		&::after {
			height: 60%;
		}
	}
}

/* @end Text overlap cards */


// Image grid
.image-grid {
	display: grid;
	gap: 14px;
	img {
		width: 100%;
		height: 100%;
		border-radius: 4px;
		margin: 0;
		object-fit: cover;
	}

	.image-grid-row {
		display: flex;
		gap: 14px;
		> div {
			flex-basis: 525px;
		}
		.wide {
			flex-basis: 747px;
		}
	}
}

@include breakpoint(medium) {
	.image-grid {
		gap: 28px;
		.image-grid-row {
			gap: 28px;
		}
	}
}
/*----------------------------------------------------------*\
    Main Content Section

    This file contains styles for the main content section,
    including sidebars.

\*----------------------------------------------------------*/

.page-content {
    background: $offwhite;
    //padding: 50px 0 30px;
}
.main-content {
	background: $white;
	color: #1f1f1f;
	padding: 40px 0 60px;
}

@include breakpoint(medium) {
	.main-content {
		padding: 80px 0 100px;
	}
}

.services-content {
	padding-top: 50px;
	.cta-wrapper {
		text-align: center;
		margin-top: 60px;
		.button {
			margin-bottom: 0;
		}
	}
}

.quick-contact-content {
	background-color: $brand-accent;
	.quick-contact-card {
		border-left: solid 3px rgba($white, 0.3);
		padding-left: 20px;
		font-family: $heading-font;
		margin-bottom: 20px;
		.heading {
			font-size: rem-calc(16);
			color: rgba($white, 0.5);
			line-height: 1.1;
			margin-bottom: 10px;
		}
		.info {
			color: $white;
			font-size: rem-calc(20);
			font-weight: $alt-bold;
			line-height: 1.1;
			margin-bottom: 10px;
		}
		a {
		  color: $white;
		  text-decoration: none;
		}
		.button {
		  	margin-bottom: 0;
		  }
	}
}

@media screen and #{breakpoint(medium)} {
	.quick-contact-content {
		.quick-contact-card {
			.heading {font-size: rem-calc(18);}
			.info {font-size: rem-calc(24);}
		}
	}
}

@media screen and #{breakpoint(large)} {
	.quick-contact-content {
			.quick-contact-card {
				.heading {font-size: rem-calc(20);}
				.info {font-size: rem-calc(32);}
			}
		}
}


.offset-content-image {
	height: 200px;
	overflow: hidden;
	margin: -80px auto 20px;
	text-align: center;
}
.offset-content-text {
	.service-card {
		margin-bottom: 30px;
	}
}
@media screen and #{breakpoint(medium)} {
	.offset-content-image {
		margin-top: -172px;
	}
	.offset-content-text {
		.service-card {
			min-height: 200px;
			margin-bottom: 30px;
		}
	}
}

@media screen and #{breakpoint(large)} {
	.offset-content-image {
		height: auto;
		overflow: hidden;
		margin-top: -172px;
		margin-left:-60px;
	}
	.offset-content-text {
		.service-card {
			min-height: 180px;
			margin-bottom: 30px;
		}
	}
}

.projects-gallery-content .project-card .title-card {
			background-color: transparent;
			height: auto;
	}
.project-card-content {
	height: 200px;
	background-color: $brand-accent;
	background-size: cover;
	background-position: center;
	margin-bottom: 30px;
	position:relative;
	.overlay {
		color: $white;
		font-size: 1.5rem;
		font-family: $alt-font;
		font-weight: $alt-bold;
		line-height: 1.1;
		padding: 30px;
		position: absolute;
		bottom: 0px;
		top:0;
		right: 0;
		left: 0;
		background-color: rgba($black, 0.2);
		transition: all .5s ease-out;
		.title {
			position: absolute;
			bottom: 30px;
		}
		&:hover {
			background-color: rgba($black, 0.6);
		}
		p {
			bottom: 0;
		}
	}
}

@media screen and #{breakpoint(medium)} {
	.projects-gallery-content {
		.project-card {
			.title-card {
			}
		}
		.project-card-content {
			height: 370px;
			.title {
				font-size: 2.125rem;
			}
		}
	}
}
/* 
@media screen and #{breakpoint(large)} {
	.projects-gallery-content {
		.project-card {

			.title-card {

			}
		}
		.project-card-content {

		}
	}
} */

.brands-content {
	background-color: $white;
	ul.media-list img {
	    max-width: 130px;
	}
}

@media screen and #{breakpoint(medium)} {
	.brands-content {
		background-color: $white;
		ul.media-list img {
		    max-width: 220px;
		}
	}
	}

.contact-content {
   background-color: $brand-accent;
   padding: 50px 0;
    a:hover, a:focus {
    	color: $white;
    }
    .button:hover, .button:focus {
    	background-color: $white;
    	color: $brand-accent;
    }
    p {
    	color: $white;
    	&.subheading {
	    	margin-top: 14px;
	    	margin-bottom: 10px;
    	}
    }
}

.contact-card {
    position: relative;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
    .title {
    	background: none;
    	color: $brand;
    }
    .label {
		background: none;
		opacity: .5;
		color: $brand;
		font-size: 1.125rem;
		font-family: $alt-font;
		float: left;
		width: 20px;
		margin-right: 10px;
		margin-bottom: 10px;
    }
    .info {
		font-size: 1rem;
		font-family: $alt-font;
		color: $white;
		float: left;
    }
    .directions-button {
    	margin-top: 20px;
    }
}



@media screen and #{breakpoint(medium)} {
	.contact-content {
    	.contact-card {
    	    z-index: 10;
    	    padding: 50px;
    	    max-width: 500px;
    	    position: absolute;
    	    top: 50%;
    	    transform: translateY(-50%);
    	    background-color: rgba(255,255,255,1);
    	    box-shadow: 0 2px 20px 0 rgba(0,0,0,0.10);
    	        .info {
    	    		font-size: 1.125rem;
    	    		color: $gray;
    	        }
    	}
	}

	.contact-card {
		padding: 50px;
	}
}
.alert-bar {
    background: $dark-blue;
    color: $white;
    text-align: center;
    padding: 10px 0 12px;
    p {
        margin: 0;
    }
    a {
        &:hover, &:focus {
            color: $white;
        }
    }
}
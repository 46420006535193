/*----------------------------------------------------------*\
   Icon

   Default icon styling and sprite icon styles.

\*----------------------------------------------------------*/

/*------------------------------------*\
    @group Default Icon Style
\*------------------------------------*/

.icons {
	width: 1em;
	height: 1em;
	fill: currentColor;
	vertical-align: middle;
	display: inline-block;
	position: relative;
	top: -.1em;
}

/* @end Default Icon Style */


/*------------------------------------*\
    @group Bitmap Sprites
\*------------------------------------*/

.ico-github {
	@include sprite($github);
}
@include breakpoint(medium) {
	.ico-github {
		@include retina-sprite($github-group);
	}
}

/* @end */
/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: fallback;
  font-weight: 400;
  src: url('/fonts/montserrat-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
       url('/fonts/montserrat-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/montserrat-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/montserrat-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/fonts/montserrat-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/montserrat-v13-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: fallback;
  font-weight: 700;
  src: url('/fonts/montserrat-v13-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
       url('/fonts/montserrat-v13-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/montserrat-v13-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/montserrat-v13-latin-700.woff') format('woff'), /* Modern Browsers */
       url('/fonts/montserrat-v13-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/montserrat-v13-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: fallback;
  font-weight: 400;
  src: url('/fonts/roboto-v19-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
       url('/fonts/roboto-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/roboto-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/roboto-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/fonts/roboto-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/roboto-v19-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: fallback;
  font-weight: 700;
  src: url('/fonts/roboto-v19-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'),
       url('/fonts/roboto-v19-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/roboto-v19-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/roboto-v19-latin-700.woff') format('woff'), /* Modern Browsers */
       url('/fonts/roboto-v19-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/roboto-v19-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

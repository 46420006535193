/*----------------------------------------------------------*\
    Lists

    In addition to standard list styles, a list with icons
    applied as ::before pseudo-elements is implemented.
    To add additional icons, follow the examples below.
    The split-list-* classes will make your list into
    multiple columns on larger views while remaining a
    single column on smaller views.

\*----------------------------------------------------------*/

ul {
	padding: 0 0 0 1em;
	margin: 1.25em 0;
	li {
		background-repeat: no-repeat;
		background-position: 2px 6px;
		padding: 0 0 0 20px;
		margin: 0 0 .5em;
		list-style: none;
		position: relative;
		&:last-child {
			margin-bottom: 0 !important;
		}
		a {
			font-weight: 700;
		}
	}
	li ul {
		margin: .666em 0 .75em;
		li {
			padding-left: 24px;
		}
	}
}
ol {
	margin: 20px 0 15px 20px;
	li {
		line-height: 1.4;
		margin: 0 0 14px;
	}
	ol {
		list-style: lower-alpha;
	}
	ol ol {
		list-style: lower-roman;
	}
}
ul.bare {
	li {
		background: none;
		padding-left: 0;
		position: relative;
	}
}
ul.media-list {
	text-align: center;
	li {
		margin: 16px 32px 16px 0;
		padding-left: 0;
		display: inline-block;
	}
	img {
		display: inline-block;
	    vertical-align: middle;
	    height: auto;
	}
}
@media screen and #{breakpoint(medium)} {
	ul.media-list {
		li {
			margin: 30px 40px 0;
		}
	}
}


ul.mark-list {
	padding: 0;
	li {
		padding-left: 33px;
		margin-bottom: 0;
		position: relative;
		&::before {
			content: '';
			background: url('/images/icons/eagle_floors_mark.svg') no-repeat left top;
			background-size: contain;
			width: 23px;
			height: 21px;
			display: block;
			position: absolute;
			left: 0;
			top: 6px;
		}
	}
}

ul.mark-list.bold-list {
	li {
		font-size: rem-calc(20);
		font-weight: $main-bold;
		margin-bottom: 10px;
	}
}

ul.icon-list {
	li {
		padding-left: 25px;
		&::before {
			content: '\f000';
			font-family: $icon-font;
			font-size: 16px;
			color: $brand;
			position: absolute;
			left: 0;
			top: 0;
		}
		li::before {
			display: none;
		}
		a.open, a:hover, a:focus {
			color: $brand;
		}
	}
	.ico-plug::before {
		content: '\f00d';
	}
	.ico-controller::before {
		content: '\f00e';
	}
	.ico-paper-video::before {
		content: '\f00f';
	}
	.ico-cloud::before {
		content: '\f010';
	}
	.ico-phone::before {
		content: '\f011';
	}
	.ico-newspaper::before {
		content: '\f012';
	}
	.ico-book::before {
		content: '\f013';
	}
}
@media screen and #{breakpoint(medium)} {
	ul {
		li {
			background-position: 2px 8px;
		}
	}
	ul.split-list-2,
	ol.split-list-2 {
		margin-left: 0;
		padding-left: 0;
	}
	ul.split-list-2 li,
	ol.split-list-2 li {
		float: left;
		width: 50%;
		padding-right: 5%;
	}
	ul.split-list-2 li:nth-child(2n+1),
	ol.split-list-2 li:nth-child(2n+1) {
		clear:left;
	}
	ul.split-list-2.clearfix li:last-child,
	ol.split-list-2.clearfix li:last-child {
		width: 100%;
	}
	ol.split-list-2 li {
		margin-right: 2%;
	}
	ul.split-list-3,
	ol.split-list-3 {
		padding-left: 0;
		margin-left: 0;
	}
	ul.split-list-3 li,
	ol.split-list-3 li {
		float: left;
		width: 33%;
		padding-right: 2%;
	}
	ol.split-list-3 li {
		margin-right: 2%;
	}
	ul.split-list-3 li:nth-child(3n+1),
	ol.split-list-3 li:nth-child(3n+1) {
		clear:left;
	}
}

/*------------------------------------*\
    @group Nav Reset
\*------------------------------------*/
nav ul {
	list-style: none;
	padding: 0;
	margin: 0;
	li {
		background: none;
		padding: 0;
		margin: 0;
	}
	li ul {
		margin: 0;
	}
	li ul li {
		background: none;
		margin: 0;
	}
	li ul li ul {
		display: none !important;
	}
	li ul li ul li {
		background: none;
	}
}
/* @end */
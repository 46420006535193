.double-image-grid {
	min-width: 0;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	grid-gap: 30px;
	margin: 48px 0;
}

@include breakpoint(800px) {
	.double-image-grid {
		grid-gap: 50px;
	}
}
.logo-grid {
	display: flex;
	flex-wrap: wrap;
	gap: 30px;
	align-items: center;
	justify-content: center;
	img {
		margin: 0;
	}
}

@include breakpoint(medium) {
	.logo-grid {
		gap: 48px 60px;
	}
}
.campaign-hero {
	background-color: #232323;
	background-size: cover;
	background-position: left top;
	color: $white;
	padding: 40px 0;
	h1 {
		font-size: rem-calc(24);
		line-height: 1.19;
		color: $brand;
	}
	p {
		font-size: rem-calc(16);
	}
	p:last-child {
		margin-bottom: 0;
	}
	.hero-content {
		background-color: rgba($brand-accent, .85);
		max-width: 570px;
		padding: 30px;
		margin: 0 auto;
	}
	&.bg-tile {background-image: url('/images/campaigns/hero/bg_black_tile.jpg');}
	&.bg-hardwood {background-image: url('/images/campaigns/hero/bg_hard_wood_floors.jpg');}
	&.bg-carpet {background-image: url('/images/campaigns/hero/bg_bedroom_carpet.jpg');}
}

@include breakpoint(medium) {
	.campaign-hero {
		padding: 52px 0;
		h1 {
			font-size: rem-calc(32);
		}
		p {
			font-size: rem-calc(20);
		}
		.hero-content {
			padding: 40px;
		}
		.column {
			min-height: 456px;
			display: flex;
			align-items: center;
		}
		&.bg-tile {background-image: url('/images/campaigns/hero/bg_black_tile_med.jpg');}
		&.bg-hardwood {background-image: url('/images/campaigns/hero/bg_hard_wood_floors_med.jpg');}
		&.bg-carpet {background-image: url('/images/campaigns/hero/bg_bedroom_carpet_med.jpg');}
	}
}

@include breakpoint(large) {
	.campaign-hero {
		.hero-content {
			margin: 0;
		}
		&.bg-tile {background-image: url('/images/campaigns/hero/bg_black_tile_lrg.jpg');}
		&.bg-hardwood {background-image: url('/images/campaigns/hero/bg_hard_wood_floors_lrg.jpg');}
		&.bg-carpet {background-image: url('/images/campaigns/hero/bg_bedroom_carpet_lrg.jpg');}
	}
}


@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
only screen and (-o-min-device-pixel-ratio: 13/10),
only screen and (min-resolution: 120dpi) {
	.campaign-hero {
		&.bg-tile {background-image: url('/images/campaigns/hero/bg_black_tile@2x.jpg');}
		&.bg-hardwood {background-image: url('/images/campaigns/hero/bg_hard_wood_floors@2x.jpg');}
		&.bg-carpet {background-image: url('/images/campaigns/hero/bg_bedroom_carpet@2x.jpg');}
	}
	@include breakpoint(medium) {
		.campaign-hero {
			&.bg-tile {background-image: url('/images/campaigns/hero/bg_black_tile_med@2x.jpg');}
			&.bg-hardwood {background-image: url('/images/campaigns/hero/bg_hard_wood_floors_med@2x.jpg');}
			&.bg-carpet {background-image: url('/images/campaigns/hero/bg_bedroom_carpet_med@2x.jpg');}
		}
	}
	@include breakpoint(large) {
		.campaign-hero {
			&.bg-tile {background-image: url('/images/campaigns/hero/bg_black_tile_lrg@2x.jpg');}
			&.bg-hardwood {background-image: url('/images/campaigns/hero/bg_hard_wood_floors_lrg@2x.jpg');}
			&.bg-carpet {background-image: url('/images/campaigns/hero/bg_bedroom_carpet_lrg@2x.jpg');}
		}
	}
}

.campaign-form-section {
	background-size: cover;
	background-color: #1e5c7c;
	background-position: left top;
	color: $white;
	padding: 40px 0 24px;
	h2 {
		font-size: rem-calc(24);
		margin-bottom: 12px;
		.highlight {
			color: $white;
		}
	}
	.text {
		max-width: 860px;
	}
}

.campaign-form {
	input[type="text"],
	input[type="email"],
	textarea {
		width: 100%;
		border: 2px solid $brand;
		margin-bottom: 20px;
	}
	input[type="text"],
	input[type="email"] {
		height: 50px;
		padding: 10px;
	}
	textarea {
		min-height: 144px;
		padding: 10px;
	}
	.field-name {
		font-weight: $main-bold;
		display: block;
	}
}

@include breakpoint(medium) {
	.campaign-form {
		display: grid;
		grid-column-gap: 30px;
		grid-template-columns: 1fr 1fr;
		.submit {
			grid-column: span 2;
		}
	}
}

@include breakpoint(large) {
	.campaign-form {
		display: block;
		.field-group {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 30px;
		}
	}
	.campaign-form-section {
		padding: 110px 0 90px;
		h2 {
			font-size: rem-calc(30);
		}
		.column {
			display: flex;
			justify-content: space-between;
		}
		.text {
			font-size: rem-calc(18);
			flex-basis: 31%;
		}
		.form {
			flex-basis: 65%;
		}
	}
}

.campaign-form-section {
	&.bg-tile {background-image: url('/images/campaigns/footer/bg_tile_backsplash.jpg');}
	&.bg-hardwood {background-image: url('/images/campaigns/footer/bg_hardwood_living_room.jpg');}
	&.bg-carpet {background-image: url('/images/campaigns/footer/bg_area_rug.jpg');}
}

@include breakpoint(medium) {
	.campaign-form-section {
		&.bg-tile {background-image: url('/images/campaigns/footer/bg_tile_backsplash_med.jpg');}
		&.bg-hardwood {background-image: url('/images/campaigns/footer/bg_hardwood_living_room_med.jpg');}
		&.bg-carpet {background-image: url('/images/campaigns/footer/bg_area_rug_med.jpg');}
	}
}

@include breakpoint(large) {
	.campaign-form-section {
		&.bg-tile {background-image: url('/images/campaigns/footer/bg_tile_backsplash_lrg.jpg');}
		&.bg-hardwood {background-image: url('/images/campaigns/footer/bg_hardwood_living_room_lrg.jpg');}
		&.bg-carpet {background-image: url('/images/campaigns/footer/bg_area_rug_lrg.jpg');}
	}
}
.media-cards {
	display: grid;
	grid-template-columns: 1fr;
	gap: 50px 30px;
	@include breakpoint(medium) {
		grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
	}
}

.media-card {
	max-width: 414px;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	border-bottom: solid 10px $brand;
	img {
		margin: 0;
	}
	ul {
		margin-top: 0;
	}
	.mark-list li {
		line-height: 1.5;
		margin-bottom: 10px;
	}
	.heading {
		font-size: rem-calc(20);
		font-weight: $main-bold;
		color: $brand-accent;
		margin-bottom: 10px;
		.name {
			font-weight: $main-normal;
		}
	}
	.text {
		background: $offwhite;
		font-size: rem-calc(20);
		padding: 20px;
		flex-grow: 1;
		> *:last-child {
			margin-bottom: 0;
		}
	}
}

@include breakpoint(800px) {
	.media-card {
		.text {
			padding: 30px;
		}
	}
}
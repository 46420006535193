$nav-bg-color: $white;

body.offscreen-nav-visible {
	position: fixed;
	&::before {
		content: '';
		width: 100%;
		height: 100%;
		background: $black;
		opacity: .75;
		z-index: 1500;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
}

.offscreen-nav {
	width: 100%;
	max-width: 375px;
	height: 100%;
	background-color: $nav-bg-color;
	padding: 20px;
	box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.15);
	position: fixed;
	top: 0;
	right: 0;
	z-index: 250;
	transform: translateX(100%);
	transition: transform .3s ease-in-out, visibility .3s ease-in-out;
	visibility: hidden;
	overflow: scroll;
	&.visible {
		z-index: 2100;
		visibility: visible;
		transform: translateX(0);
		transition: transform .3s ease-in-out, visibility .3s ease-in-out;
	}
	a, button {
		background: none;
		color: $brand-accent;
		padding: 0;
		border-bottom: 0;
		cursor: pointer;
		transition: color .3s;
		&.current,
		&:hover,
		&:focus-visible {
			color: $brand;
		}
	}
	.has-dropdown {
		button {
			width: 100%;
			text-align: left;
			display: block;
			&::after {
				@include css-triangle(8px, currentColor, down);
				position: absolute;
				top: 8px;
				right: 0;
				transition: transform .2s;
			}
			&.active::after {
				transform: rotate(180deg);
			}
		}
	}
	.top-level {
		li {
			padding-bottom: 25px;
			margin-bottom: 0;
			&:last-child {
				padding-bottom: 0;
			}
		}
		.nav-text {
			font-family: $main-font;
			font-size: rem-calc(24);
			font-weight: $main-bold;
			line-height: 1.12;
		}
		.nav-caret {
			width: 30px;
			height: 30px;
			color: $brand-alt;
			display: flex;
			align-items: center;
			justify-content: center;
			svg {
				transition: transform .3s;
			}
			&.active svg,
			&:hover svg {
				transform: rotate(180deg);
			}
		}
	}
	.second-level {
		padding-left: 10px;
		margin: 0;
		li {
			font-size: rem-calc(16);
			line-height: 1;
			padding: 0;
			padding-top: 10px;
			&:first-child {
				padding-top: 10px;
			}
		}
		a {
			color: $brand-accent;
			&.current,
			&:hover {
				color: $brand;
			}
			.nav-item-tag {
				margin-left: 0;
			}
		}
	}
}

.offscreen-nav-header {
	margin-bottom: 60px;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	a, button {
		display: inline-block;
	}
	img {
		margin: 0;
	}
	.nav-toggle {
		padding-left: 20px;
	}
}
.grid-col-2 {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 0 30px;
	@include breakpoint(450px) {
		grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
	}
	.col {
		width: 100%;
		max-width: 600px;
		margin: 0 auto;
	}
}
/*----------------------------------------------------------*\
    Buttons

    Most button styles below can be combined.
    For example, you could have small brand accent button by
	combining the .button.brand-accent.small classes.

\*----------------------------------------------------------*/


.button,
form input[type="submit"] {
	background-color: $brand;
	color: $white;
	font-size: rem-calc(16);
	font-family: $sans-serif-font;
	padding: 12px 20px;
	text-transform: uppercase;
	line-height: 1.1;
	font-weight: $alt-bold;
	border: none;
	display: inline-block;
	line-height: 1.2;
	transition: color .3s, background-color .3s;
	&:hover,
	&:focus {
		background-color: $brand-accent;
		color: $white;
		text-decoration: none;
	}
}
.button.small {
	font-size: rem-calc(14);
	padding: 8px 13px;
}
.button.large {
	font-size: rem-calc(20);
	padding: 18px 23px;
}
.button.short {
	padding-top: 8px;
	padding-bottom: 8px;
}
.button.alt {
	background-color: $brand;
	color: $white;
	&:hover,
	&:focus {
		background: $white;
		color: $brand-accent;
	}
}
.button.outline {
	background: none;
	color: $white;
	border: solid 1px $white;
	&:hover,
	&:focus {
		background: $white;
		color: $brand;
	}
}
.button.white {
	background: $white;
	color: $brand-accent;
	&:hover,
	&:focus {
		background: $brand-accent;
		color: $white;
	}
}

.gallery-button {
	font-size: rem-calc(20);
	font-weight: $main-bold;
	display: inline-flex;
	align-items: center;
	.icon {
		margin-right: 10px;
	}
}

@media screen and #{breakpoint(medium)} {
	.button, form input[type="submit"] {
		font-size: rem-calc(18);
		padding: 16px 22px;
	}
	.button.large {
		font-size: rem-calc(24);
		padding: 18px 23px;
	}
}
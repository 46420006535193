/*----------------------------------------------------------*\
    Variables

    This file contains all (non-Foundation) globally-scoped
    variables used throughout the Sass files. We strongly
    encourage sticking to these variables instead of
    hard-coded values as often as possible.

\*----------------------------------------------------------*/

//Font families
$sans-serif-font: 'Montserrat', sans-serif;
$serif-font: 'Roboto', sans-serif;;
$icon-font: 'FontAwesome';
$heading-font: $sans-serif-font;
$main-font: $serif-font;
$alt-font: $heading-font;

//Font weights
$main-normal: 400;
$main-bold: 700;
$alt-normal: 400;
$alt-bold: 700;
$heading-normal: $alt-normal;

//Styleguide colors
$brand: #39BBEC;
$brand-accent: #024f79;
$brand-alt: #f6b418;
$brand-alt-accent: #65bc45;
$white : #FFFFFF;
$black : #000000;
$gray : #7E7E7E;
$dark-blue: #022e46;
$dark-gray: #5d5d5d;
$light-gray: #e6e7e8;
$offwhite: #F7F7F7;

$colors: (
	'brand': $brand,
	'brand-accent': $brand-accent,
	'brand-alt': $brand-alt,
	'brand-alt-accent': $brand-alt-accent,
	'white' : $white,
	'black' : $black,
	'gray': $gray,
	'dark-blue' : $dark-blue,
	'dark-gray' : $dark-gray,
	'light-gray' : $light-gray,
	'offwhite' : $offwhite
);

//Gradients, for use on backgrounds only
$gradients: ( 'white': linear-gradient(180deg, rgba(231, 231, 231, 1) 0%, rgba(255, 255, 255, 1) 100%));

//Transparent background colors
$brand-trans : rgba($brand, 0.8);
$black-trans : rgba($black, 0.7);

//Link colors
$link-base: $brand;
$link-active: $brand-accent;

// Main body color, use when adding this color elsewhere (form fields as an example)
$main-color: $dark-gray;

//Social media brand colors
$social: (
	'twitter': #00aced,
	'facebook': #3b5998,
	'googleplus': #dd4b39,
	'pinterest': #cb2027,
	'linkedin': #007bb6,
	'youtube': #bb0000,
	'vimeo': #aad450,
	'instagram': #517fa4,
	'quora': #a82400,
	'foursquare': #0072b1,
	'stumbleupon': #EB4823,
	'soundcloud': #ff3a00)
;

// Base spacing
$base-spacing: 24px;

//Shadows
$text-shadow: 1px 0 1px rgba(0, 0, 0, 0.65);
$text-shadow-light: 1px 1px 1px rgb(255, 255, 255);
$box-shadow: 0 2px 20px 0 rgba(0,0,0,0.10);
$box-shadow-lrg: 0 2px 20px 0 rgba(0,0,0,0.10);

//Rounded corners
$border-round: 1000px;
$border-radius: 5px;
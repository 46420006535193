.media-section-full {
	background: $brand-accent;
	color: $white;
	padding-bottom: 30px;
	.content {
		max-width: 450px;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		gap: 50px;
		h2 {
			color: $white;
		}
	}
	.media {
		padding-top: 20px;
		img {
			width: auto;
			margin: 0;
		}
	}
	.text {
		> *:last-child {
			margin-bottom: 0;
		}
	}
}

@include breakpoint(950px) {
	.media-section-full {
		padding-bottom: 0;
		&.content-top {
			.text {
				padding: 96px 0;
				align-self: flex-start;
			}
		}
		.content {
			max-width: 100%;
			flex-direction: row;
			h2 {
				font-size: rem-calc(32);
				margin-bottom: 30px;
			}
		}
		.text {
			padding: 30px 0;
			flex-basis: 600px;
			order: 0;
			align-self: center;
		}
		.media {
			padding-top: 0;
			flex-basis: 650px;
			order: 1;
			img {
				height: 100%;
				object-fit: cover;
			}
		}
	}
	.media-section-full.flip {
		.text {
			order: 1;
		}
		.media {
			order: 0;
		}
	}
}
/*------------------------------------*\
    @group Home Template
\*------------------------------------*/

.service-area-section {
	background: $offwhite;
	padding: 50px 15px;
	h3 {
		font-size: rem-calc(24);
		color: $brand;
		text-align: center;
		margin-bottom: 60px;
		margin-top: 10px;
	}
	.service-area-map {
		text-align: center;
	}
	.service-area-text {
		max-width: 580px;
		font-size: rem-calc(22);
		line-height: 1.45;
		margin: 0 auto;
	}
	.service-area-locations {
		margin-bottom: 20px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		ul {
			padding: 0 15px 0 0;
			margin: 0;
		}
		li {
			padding-left: 33px;
			margin-bottom: 0;
			position: relative;
			&::before {
				content: '';
				background: url('/images/cssimg/li_icon.png') no-repeat left top;
				width: 23px;
				height: 21px;
				display: block;
				position: absolute;
				left: 0;
				top: 6px;
			}
		}
	}
}

@include breakpoint(large) {
	.service-area-section {
		padding: 50px;
		.service-area-inner {
			display: flex;
			justify-content: space-between;
			flex-wrap: nowrap;
		}
		.service-area-map {
			padding-right: 30px;
			flex-shrink: 0;
		}
		.service-area-text {
			margin: 0 0 0 auto;
		}
		.service-area-locations {
			ul:first-child {
				padding-right: 100px;
			}
		}
	}
}


.brands-content {
	padding-bottom: 50px;
	h2 {
		font-size: rem-calc(24);
		color: $brand;
		line-height: 1.25;
	}
}

.contact-content {
	font-size: rem-calc(22);
	color: $white;
	h2 {
		font-size: rem-calc(32);
		color: $white;
		line-height: 1.06;
	}
	form {
		margin-top: 32px;
		margin-bottom: 50px;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
		grid-gap: 20px 30px;
		.field {

		}
		.field-full {
			grid-column: 1 / -1;
		}
		label span {
			font-size: rem-calc(14);
			font-weight: $main-bold;
			line-height: 1.5;
			text-transform: uppercase;
			display: block;
		}
		input[type="text"],
		input[type="email"],
		input[type="phone"],
		textarea {
			width: 100%;
			border: none;
			box-shadow: none;
			border-radius: 4px;
		}

		input[type="text"],
		input[type="email"],
		input[type="phone"] {
			height: 50px;
			padding: 10px;
		}

		.checkbox-list p {
			font-size: rem-calc(14);
			font-weight: $main-bold;
			line-height: 1.5;
			text-transform: uppercase;
			margin-bottom: 5px;
		}
		.checkbox-list .checkboxes {
			display: flex;
			flex-wrap: wrap;
			label {
				margin-right: 20px;
				display: flex;
			}
			span {
				font-size: rem-calc(14);
				font-weight: $main-normal;
				text-transform: none;
				margin-left: 6px;
				display: inline-block;
			}
		}
	}
	.map-embed {
		margin-bottom: 20px;
	}
	.contact-option {
		margin-bottom: 15px;
		display: flex;
		a {
			word-wrap: break-word;
			word-break: break-word;
		}
		.label {
			font-weight: $main-bold;
			margin-right: 10px;
		}
	}
	.contact-employee-info {
		display: flex;
		flex-direction: column;
		@include breakpoint(1100px) {
			flex-direction: row;
			gap: 30px;
		}
		.heading {
			font-size: 1em;
			color: $white;
			margin-bottom: 0;
		}
		.break-long {
			overflow-wrap: anywhere;
		}
	}
}


@include breakpoint(medium) {
	.contact-content {
		form {
			margin-bottom: 0;
		}
	}
}


/* @end Home Template */
.faqs {
	h2 {
		font-family: $main-font;
		font-size: rem-calc(20);
		font-weight: $main-bold;
		color: $brand-accent;
		line-height: 1.3636;
		margin-top: 48px;
		margin-bottom: 12px;
	}
	p, ul {
		font-size: rem-calc(18);
	}
	.mark-list li {
		margin-bottom: 6px;
		a {
			font-weight: $main-normal;
		}
	}
}

@include breakpoint(medium) {
	.faqs {
		h2 {
			font-size: rem-calc(26);
			line-height: 1.2;
		}
		p, ul {
			font-size: rem-calc(20);
		}
	}
}
.team-cards {
	display: grid;
	grid-gap: 48px 30px;
	@include breakpoint(400px) {
		grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
	}
}

.team-cards.wide {
	grid-template-columns: 1fr;
	@include breakpoint(900px) {
		grid-template-columns: 1fr 1fr;
	}
	.team-card {
		max-width: 636px;
	}
}

.team-card {
	max-width: 414px;
	margin: 0 auto;
	> *:last-child {
		margin-bottom: 0;
	}
	.name {
		color: $dark-gray;
	}
	.title {
		font-size: rem-calc(18);
		display: block;
	}
	img {
		margin-bottom: 32px;
	}
	p {
		font-family: $alt-font;
		font-weight: $alt-bold;
		margin-bottom: 0;
	}
	.name {
		font-size: rem-calc(32);
		color: $brand-accent;
		line-height: 1.06;
	}
	.title {
		font-size: rem-calc(18);
	}
}
/*----------------------------------------------------------*\
    Page Footer Section

    This file contains all styles for the footer section.

\*----------------------------------------------------------*/


.page-footer {
    font-size: 14px;
    padding: 27px 0 54px;
    .p {
        margin: 0 0 10px;
        padding-top: 13px;
    }
    nav {
        text-align: center;
        margin-bottom: 24px;
    }
    .social-icons {
        justify-content: center;
    }
    .footer-logo {
        text-align: center;
        margin-bottom: 1rem;
    }
}
@media screen and #{breakpoint(medium)} {
    .page-footer {
        padding: 54px 0 71px;
        nav {
            text-align: left;
        }
        .social-icons {
            justify-content: flex-end;
        }
    }
}

.page-footer nav a {
    font-weight: 400;
    color: $brand;
    text-transform: uppercase;
}
    .page-footer nav a:hover,
    .page-footer nav a:focus,
    .page-footer nav li.selected > a {
        color: $brand-accent;
    }
.page-footer nav li {
    font-size: 1rem;
    margin: 0 20px;
    display: inline-block;
}
.page-footer nav li ul {
    display: none;
}

/* .page-footer .social-media {
    font-size: 30px;
    margin: 0 0 20px;
    padding: 0;
    list-style: none;
    float: right;
    li {
        margin: 0 0 0 10px;
        padding: 0;
        line-height: 1;
        float: left;
        &:first-child {
            margin-left: 0;
        }
        &:before {
            display: none;
        }
    }
} */

.page-footer .copyright {
    p {
        font-size: rem-calc(16);
        margin: 0;
        color: rgba($gray, 0.5);
        text-align: center;
    }
}

@media screen and #{breakpoint(medium)} {
    .page-footer nav ul {
        text-align: right;
    }
    .page-footer nav li {
        margin: 0 0 0 40px;
    }
    .page-footer .copyright {
        p {
            margin: 0;
            text-align: right;
        }
    }
}


.page-footer .valitics-atrib {
    text-align: right;
    p {
        margin: 0;
    }
    img {
        margin-top: -12px;
        margin-bottom: 0;
    }
}

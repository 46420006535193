.logo-intro-section {
	text-align: center;
	margin: 48px 0 96px;
	h2 {
		font-size: rem-calc(24);
		line-height: 1.25;
	}
	img {
		margin: 0;
	}
	.images {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		gap: 30px;
	}
}
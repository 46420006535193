.media-object-checkerboard {
	.item {
		background: $offwhite;
		max-width: 650px;
		margin-left: -10px;
		margin-right: -10px;
	}
	.text {
		padding: 30px 30px 60px;
		> *:last-child {
			margin-bottom: 0;
		}
	}
	.media {
		img {
			margin: 0;
		}
	}
}

@include breakpoint(650px) {
	.media-object-checkerboard {
		.item {
			background: $offwhite;
			margin: 0 auto;
		}
	}
}

@include breakpoint(850px) {
	.media-object-checkerboard {
		.item:nth-child(odd) {
			.media {
				order: 1;
			}
			.text {
				order: 0;
			}
		}
		.item {
			max-width: 100%;
			display: flex;
		}
		.media {
			flex-basis: 50%;
			img {
				object-fit: cover;
				height: 100%;
			}
		}
		.text {
			flex-basis: 50%;
			padding-bottom: 30px;
		}
	}
}

@include breakpoint(1100px) {
	.media-object-checkerboard {
		font-size: rem-calc(20);
		.text {
			padding: 50px;
		}
	}
}
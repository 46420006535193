// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$facebook-name: 'facebook';
$facebook-x: 0px;
$facebook-y: 120px;
$facebook-offset-x: 0px;
$facebook-offset-y: -120px;
$facebook-width: 121px;
$facebook-height: 120px;
$facebook-total-width: 291px;
$facebook-total-height: 240px;
$facebook-image: 'production/images/sprites.png';
$facebook: (0px, 120px, 0px, -120px, 121px, 120px, 291px, 240px, 'production/images/sprites.png', 'facebook', );
$github-name: 'github';
$github-x: 149px;
$github-y: 0px;
$github-offset-x: -149px;
$github-offset-y: 0px;
$github-width: 142px;
$github-height: 120px;
$github-total-width: 291px;
$github-total-height: 240px;
$github-image: 'production/images/sprites.png';
$github: (149px, 0px, -149px, 0px, 142px, 120px, 291px, 240px, 'production/images/sprites.png', 'github', );
$twitter-name: 'twitter';
$twitter-x: 0px;
$twitter-y: 0px;
$twitter-offset-x: 0px;
$twitter-offset-y: 0px;
$twitter-width: 149px;
$twitter-height: 120px;
$twitter-total-width: 291px;
$twitter-total-height: 240px;
$twitter-image: 'production/images/sprites.png';
$twitter: (0px, 0px, 0px, 0px, 149px, 120px, 291px, 240px, 'production/images/sprites.png', 'twitter', );
$youtube-name: 'youtube';
$youtube-x: 121px;
$youtube-y: 120px;
$youtube-offset-x: -121px;
$youtube-offset-y: -120px;
$youtube-width: 120px;
$youtube-height: 120px;
$youtube-total-width: 291px;
$youtube-total-height: 240px;
$youtube-image: 'production/images/sprites.png';
$youtube: (121px, 120px, -121px, -120px, 120px, 120px, 291px, 240px, 'production/images/sprites.png', 'youtube', );
$facebook-2x-name: 'facebook@2x';
$facebook-2x-x: 0px;
$facebook-2x-y: 240px;
$facebook-2x-offset-x: 0px;
$facebook-2x-offset-y: -240px;
$facebook-2x-width: 242px;
$facebook-2x-height: 240px;
$facebook-2x-total-width: 582px;
$facebook-2x-total-height: 480px;
$facebook-2x-image: 'images/sprites@2x.png';
$facebook-2x: (0px, 240px, 0px, -240px, 242px, 240px, 582px, 480px, 'images/sprites@2x.png', 'facebook@2x', );
$github-2x-name: 'github@2x';
$github-2x-x: 298px;
$github-2x-y: 0px;
$github-2x-offset-x: -298px;
$github-2x-offset-y: 0px;
$github-2x-width: 284px;
$github-2x-height: 240px;
$github-2x-total-width: 582px;
$github-2x-total-height: 480px;
$github-2x-image: 'images/sprites@2x.png';
$github-2x: (298px, 0px, -298px, 0px, 284px, 240px, 582px, 480px, 'images/sprites@2x.png', 'github@2x', );
$twitter-2x-name: 'twitter@2x';
$twitter-2x-x: 0px;
$twitter-2x-y: 0px;
$twitter-2x-offset-x: 0px;
$twitter-2x-offset-y: 0px;
$twitter-2x-width: 298px;
$twitter-2x-height: 240px;
$twitter-2x-total-width: 582px;
$twitter-2x-total-height: 480px;
$twitter-2x-image: 'images/sprites@2x.png';
$twitter-2x: (0px, 0px, 0px, 0px, 298px, 240px, 582px, 480px, 'images/sprites@2x.png', 'twitter@2x', );
$youtube-2x-name: 'youtube@2x';
$youtube-2x-x: 242px;
$youtube-2x-y: 240px;
$youtube-2x-offset-x: -242px;
$youtube-2x-offset-y: -240px;
$youtube-2x-width: 240px;
$youtube-2x-height: 240px;
$youtube-2x-total-width: 582px;
$youtube-2x-total-height: 480px;
$youtube-2x-image: 'images/sprites@2x.png';
$youtube-2x: (242px, 240px, -242px, -240px, 240px, 240px, 582px, 480px, 'images/sprites@2x.png', 'youtube@2x', );
$spritesheet-width: 291px;
$spritesheet-height: 240px;
$spritesheet-image: 'production/images/sprites.png';
$spritesheet-sprites: ($facebook, $github, $twitter, $youtube, );
$spritesheet: (291px, 240px, 'production/images/sprites.png', $spritesheet-sprites, );
$retina-spritesheet-width: 582px;
$retina-spritesheet-height: 480px;
$retina-spritesheet-image: 'images/sprites@2x.png';
$retina-spritesheet-sprites: ($facebook-2x, $github-2x, $twitter-2x, $youtube-2x, );
$retina-spritesheet: (582px, 480px, 'images/sprites@2x.png', $retina-spritesheet-sprites, );

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.
//
// The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
$facebook-group-name: 'facebook';
$facebook-group: ('facebook', $facebook, $facebook-2x, );
$github-group-name: 'github';
$github-group: ('github', $github, $github-2x, );
$twitter-group-name: 'twitter';
$twitter-group: ('twitter', $twitter, $twitter-2x, );
$youtube-group-name: 'youtube';
$youtube-group: ('youtube', $youtube, $youtube-2x, );
$retina-groups: ($facebook-group, $github-group, $twitter-group, $youtube-group, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
//   It should be used with a "retina group" variable.
//
// The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
//
// $icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );
//
// .icon-home {
//   @include retina-sprite($icon-home-group);
// }
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
         (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

// The `retina-sprites` mixin generates a CSS rule and media query for retina groups
//   This yields the same output as CSS retina template but can be overridden in SCSS
//
// @include retina-sprites($retina-groups);
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}
